import { classTicketStatusConfig } from '@/config/classTicket'
import { formatDate } from '@/utils/date'
import { get } from 'lodash'

export const useClassTicket = () => {
  const classTicketStatusLabel = (data) => {
    return get(classTicketStatusConfig, `${data.status}.label`)
  }

  const formatExpPreviewText = ({ isExp, expType, expDay, endOfPeriodUnit, specifyExpiredDate }) => {
    if (!isExp) return '無期限'
    if (expType === 'specify') return formatDate(specifyExpiredDate, 'YYYY-MM-DD')
    else if (expType === 'expDay') return `領取後 ${expDay} 天`
    else if (expType === 'endOfPeriod') {
      if (endOfPeriodUnit === 'month') return '領取當月最後一天'
    }
    return '-'
  }

  return { classTicketStatusLabel, formatExpPreviewText }
}
